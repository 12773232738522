<!-- @format -->

<template>
	<layout-default-new>
		<div>
			<sub-navbar page="Order" />
		</div>
		<section class="hero is-success margin-bottom-20">
			<div class="hero-body">
				<p class="title">Edit / View order</p>
				<p class="subtitle">Please select the dispatch to preview</p>
			</div>
		</section>
		<div v-if="loading" style="font-weight: 900; color: red">...Loading</div>
		<div v-if="!loading">
			<div
				style="
					background-color: rgb(76, 92, 122);
					padding: 20px;
					font-weight: 900;
					margin-bottom: -10px;
				"
			>
				<div style="color: white; margin-bottom: 20px">
					Between the dates: &nbsp; {{ beginningDate }} &nbsp; and &nbsp;
					{{ endingDate }}
				</div>
				<div style="display: flex; justify-content: start; font-weight: 900">
					<b-button
						class="margin-right-20"
						:type="buttonType"
						style="width: 250px"
						@click="toggleDays"
						>{{ buttonTag }}</b-button
					>
					<div
						class="content margin-right-10"
						style="color: white; margin-top: 10px"
					>
						<b>Selection:</b>
					</div>
					<div style="color: white">
						<b-radio
							v-model="radio"
							type="is-warning"
							name="name"
							native-value="Order is draft and not dispatched"
							style="margin-top: 10px"
						>
							Draft
						</b-radio>
						<b-radio
							v-model="radio"
							type="is-warning"
							name="name"
							native-value="Order is locked and not dispatched"
							style="margin-top: 10px"
						>
							Not dispatched
						</b-radio>
						<b-radio
							v-model="radio"
							type="is-warning"
							name="name"
							native-value="Order is locked and dispatched"
							style="margin-top: 10px"
						>
							Dispatched
						</b-radio>
						<b-radio
							v-model="radio"
							name="name"
							type="is-warning"
							native-value="All"
							style="margin-top: 10px"
						>
							All
						</b-radio>
					</div>
				</div>
			</div>
			<div class="margin-bottom-30">
				<ve-table
					:rows="rowsList"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:sort-options="sortOptions"
				>
				</ve-table>
			</div>
			<div v-if="isModalVisible">
				<ModalOrderFrame :onClose="onClose" />
			</div>
			<!-- <div v-if="!!isModalVisible">
				<modal-order-frame />
			</div> -->
		</div>
	</layout-default-new>
</template>

<script>
import ModalOrderFrame from '@/_srcv2/pages/_reports/get-data-and-report/order/ModalOrderFrame.vue'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import GetOrders from '@/_srcv2/pages/order/view-order/graphql/GetOrders.graphql'
import { reactive, onMounted, ref, watch, computed } from '@vue/composition-api'
import Store from '@/store'
import Router from '@/router'
import { useQuery } from '@vue/apollo-composable'
import storeOrder from '@/_srcv2/pages/order/_shared/useOrderState.js'
import storeOrderHeaders from '@/_srcv2/pages/order/add-order/subcomponents/get-order-headers/useOrderHeaderState.js'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import dateformat from 'dateformat'

export default {
	name: 'ViewOrder',
	components: {
		SubNavbar,
		ModalOrderFrame,
	},
	setup() {
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'orderNumber', type: 'desc' },
		}
		const radio = ref('Order is draft and not dispatched')
		const buttonType = ref('is-success')
		// ! -----------------------------------------------------------------
		const today = ref(new Date())
		const days = ref(92)
		const buttonTag = ref('Get without time limited')
		const p1 = (condition) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					console.log('Condition is ', condition)
					if (days.value === 92) {
						days.value = 1500
					} else {
						days.value = 92
					}
					if (buttonTag.value === 'Get without time limited') {
						buttonTag.value = 'Get last three months'
					} else {
						buttonTag.value = 'Get without time limited'
					}
					if (buttonType.value === 'is-success') {
						buttonType.value = 'is-info'
					} else {
						buttonType.value = 'is-success'
					}
					resolve(true)
				} else {
					const error = new Error('Condition is false')
					reject(error)
				}
			}).catch((err) => console.log('Error: ', err))
		}

		const toggleDays = () => {
			console.log('toggle is fired')
			p1(true).then(() => setInitialValues(radio.value))
		}
		const beginningDate = computed(() => {
			const bd = new Date().setTime(
				today.value - days.value * 24 * 60 * 60 * 1000,
			)
			console.log('bd', bd)
			console.log('myDate', dateformat(bd, 'yyyy-mm-dd'))
			return dateformat(bd, 'yyyy-mm-dd')
		})
		const endingDate = computed(() => {
			return dateformat(today.value, 'yyyy-mm-dd')
		})
		console.log('**** endingDate.value', endingDate.value)
		console.log('**** typeof endingDate.value', typeof endingDate.value)
		// ! -----------------------------------------------------------------
		const filterArray = (searchString, array) => {
			const checkItem = (item) => {
				return JSON.stringify(item).includes(searchString)
			}
			switch (searchString) {
				case 'All':
					return array
				case '':
					return []
				default:
					return array.filter(checkItem)
			}
		}
		// ! ---------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})

		const { refetch, loading } = useQuery(
			GetOrders,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: beginningDate.value,
				ed: endingDate.value,
			}),
			options,
		)
		const columnsList = ref([
			{
				label: 'Select',
				field: 'select',
				width: '50px',
				sortable: false,
			},
			{
				label: 'Order Date',
				field: 'date',
				width: '120px',
			},
			{
				label: 'Order Number',
				field: 'orderNumber',
				width: '105px',
			},
			{
				label: 'Customer ID',
				field: 'id',
				width: '135px',
			},
			{
				label: 'Customer Title',
				field: 'title',
			},
			{
				label: 'Customer Nickname',
				field: 'nickname',
			},
			{
				label: 'Order Status',
				field: 'status',
			},
		])
		// ! -------------------------------------------------------------
		const rawRowsList = ref([])
		const rowsList = ref([])
		const setInitialValues = (term) => {
			console.log('*********setInitialValues is fired')
			refetch()
				.then((result) => {
					console.log('*********result', result.data.order_headers)
					rawRowsList.value = result.data.order_headers.map((item) => {
						const note1 = item.order_lock ? 'locked' : 'draft'
						const note2 = item.order_dispatched
							? 'dispatched'
							: 'not dispatched'
						let note = `Order is ${note1} and ${note2}`
						return {
							date: item.order_date,
							orderNumber: item.order_number,
							id: item.customer.customer_id,
							title: item.customer.customer_title,
							nickname: item.customer.customer_nickname,
							status: note,
							ourCompany: item.company_id,
							isOrderLocked: item.order_lock,
							isOrderDispatched: item.order_dispatched,
							reference: item.customer.customer_reference,
							email: item.customer.customer_email,
							orgNum: item.customer.customer_org_num,
							excRate: item.order_exchange_rate,
							documentType: 'order',
							orderType: item.order_type,
						}
					})
				})
				.then(() => {
					rowsList.value = filterArray(term, rawRowsList.value)
				})
		}
		onMounted(() => {
			// setInitialValues()
		})
		// -------------------------------------------------------------------------------
		const { reportOurCompany, reportOrderNumber } = storeOrder()
		const { orderHeaderState, modelOrder } = storeOrderHeaders()
		const storeDataAndGetReport = (row) => {
			if (row.isOrderLocked) {
				getPdfReport(row)
			} else {
				console.log('storeDataAndGetReport is fired orderNotLocked')
				reportOurCompany.value = row.ourCompany
				reportOrderNumber.value = row.orderNumber
				orderHeaderState.ourCompanyOrder = row.ourCompany
				modelOrder.orderExchangeRate = row.excRate.toString()
				modelOrder.orderType = row.orderType
				orderHeaderState.customerIdOrder = row.id
				orderHeaderState.customerTitleOrder = row.title
				orderHeaderState.customerNicknameOrder = row.nickname
				orderHeaderState.customerReferenceOrder = row.reference
				orderHeaderState.customerEmailOrder = row.email
				orderHeaderState.customerOrgNumOrder = row.orgNum
				orderHeaderState.orderIssueDate = row.date
				Router.push({ name: 'EditOrder' })
			}
		}
		const {
			reportDocumentType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			isSelectedDocumentLocked,
			isModalVisible,
			reportCustomer,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
			isUnlockButtonVisible,
			keyProcessReport,
		} = useProcessReportStore()
		const setVariables = (row) => {
			return new Promise((resolve, reject) => {
				if (row !== null || undefined) {
					console.log('---------- row', row)
					reportDocumentType.value = row.documentType
					reportDocumentNumber.value = row.orderNumber
					reportDocumentOurCompany.value = row.ourCompany
					isSelectedDocumentLocked.value = row.isOrderLocked
					isSelectedDocumentReported.value = row.isOrderDispatched
					isUnlockButtonVisible.value = !row.isOrderDispatched
					reportCustomer.value = `${row.id} - ${row.title} - ${row.nickname}`
					reportDocumentDate.value = row.date
					reportDocumentStatus.value = row.status
					keyProcessReport.value += 1
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// -----------------------------------------------------------------------------------
		const getPdfReport = (row) => {
			setVariables(row).then(() => (isModalVisible.value = true))
		}
		// ---------------------------------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log(params.row)
				storeDataAndGetReport(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			storeDataAndGetReport(params.row)
		}
		// ! ------------------------------------------------------------------------------
		const computedKey = computed(() => Store.getters.getKeyTable)
		watch(
			() => computedKey.value,
			() => {
				setInitialValues(radio.value)
			},
		)
		watch(
			() => radio.value,
			() => {
				setInitialValues(radio.value)
			},
		)
		const onClose = () => {}
		// ! ------------------------------------------------------------------------------
		return {
			sortOptions,
			loading,
			columnsList,
			rowsList,
			onCellClick,
			onRowDoubleClick,
			isModalVisible,
			radio,
			beginningDate,
			endingDate,
			toggleDays,
			buttonTag,
			buttonType,
			onClose,
		}
	},
}
</script>

<style scoped></style>
