import { render, staticRenderFns } from "./ModalOrderFrame.vue?vue&type=template&id=596b0b1d&scoped=true&"
import script from "./ModalOrderFrame.vue?vue&type=script&lang=js&"
export * from "./ModalOrderFrame.vue?vue&type=script&lang=js&"
import style0 from "./ModalOrderFrame.vue?vue&type=style&index=0&id=596b0b1d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596b0b1d",
  null
  
)

export default component.exports